
const NOTUSEDASATRIGGER = 'not-used-as-a-trigger'
const NOPARTYPREFERENCE = 'none'
const PARTYTRIGGERVALUES = [ 'not-used-as-a-trigger', 'democratic', 'republican', 'none', 'other' ]
const VALUE_FIELDS_DELIMITER = '--'

export default {
  name: 'State-Special',
  props: {
    value: {
      type: String,
      default: ''
    },
    registrationStatus: {
      type: String,
      default: ''
    },
    abroadStatus: {
      type: String,
      default: ''
    },
    partyAffiliation: {
      type: String,
      default: ''
    },
    identificationStatus: {
      type: String,
      default: ''
    },
    stateRules: {
      type: Array,
      default: []
    },
    validations: {
      type: Object,
      default: () => ({})
    },
    dict: {
      type: Object,
      default: () => ({})
    },
  },
  data () {
    return {
      inputValue: this.getInputValueArray(),
      isToolTipOpen: this.getToolTipOpenArray(),
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    isTriggered () {
      let triggers = []

      for (let ii=0; ii<this.stateRules.length; ii++) {
        if (!this.stateRules[ii].isenabled) {
          triggers.push(false)
          continue
        }

        let trig = true

        if (this.stateRules[ii].registration_trigger && 
            this.stateRules[ii].registration_trigger!==NOTUSEDASATRIGGER)
        {
          trig = trig && (this.stateRules[ii].registration_trigger.split('-or-').indexOf(this.registrationStatus.toLowerCase())>-1)
        }

        if (this.stateRules[ii].abroad_trigger && 
            this.stateRules[ii].abroad_trigger!==NOTUSEDASATRIGGER)
        {
          trig = trig && (this.stateRules[ii].abroad_trigger.split('-or-').indexOf(this.abroadStatus.toLowerCase())>-1)
        }

        if (this.stateRules[ii].party_trigger && 
            this.stateRules[ii].party_trigger!==NOTUSEDASATRIGGER)
        {
          let party = this.partyAffiliation
          if (party) {
            party = party.toLowerCase()

            // if the voter selects "Other", then the partyAffiliation becomes the name of the party.
            // that is, the partyAffiliation does not remain "Other"
            // in this case we set party to "other" so party is among the valid trigger values in stateRules[].party_trigger
            party = (PARTYTRIGGERVALUES.indexOf(party)<0) ? "other" : party
          } else {
            // the voter can leave the political party field empty.
            // we could treat this case as "no party preference"
            // for now we ignore "empty"
            // party = (party) ? party : NOPARTYPREFERENCE
          }

          trig = trig && (this.stateRules[ii].party_trigger.split('-or-').indexOf(party)>-1)
        }

        if (this.stateRules[ii].identification_trigger && 
            this.stateRules[ii].identification_trigger!==NOTUSEDASATRIGGER)
        {
          trig = trig && (this.stateRules[ii].identification_trigger.split('-or-').indexOf(this.identificationStatus.toLowerCase())>-1)
        }

        trig = this.stateRules[ii].is_always_triggered || trig

        triggers.push(trig)
      }

      return triggers
    },
    isAtLeastOneTrigger () {
      let t = false
      for (let ii=0; ii<this.isTriggered.length; ii++) {
        t = t || this.isTriggered[ii]
      }
      return t
    },
    isAllTriggersAnswered () {
      let ans = true
      for (let ii=0; ii<this.rulesEnabled.length; ii++) {
        if (this.isTriggered[ii]) {
          ans = ans && (this.inputValue[ii] ? true : false)
        }
      }
      return ans
    },
    rulesEnabled () {
      let r = []
      for (let ii=0; ii<this.stateRules.length; ii++) {
        if (this.stateRules[ii].isenabled) {
          r.push(ii)
        }
      }
      return r
    },
    rulesIndices () {
      return new Array(this.stateRules.length)
    },
    isAllRulesAnswered () {
      let ans = true
      for (let ii=0; ii<this.rulesEnabled.length; ii++) {
        ans = ans && this.inputValue[this.rulesEnabled[ii]]
      }
      return ans
    },
    getAllValues () {
      if (this.isAllTriggersAnswered) {
        let reply = ''

        for (let ii=0; ii<this.rulesEnabled.length; ii++) {
          if (this.isTriggered[ii]) {
            reply = reply + ( this.inputValue[ii] ? this.stateRules[ii].voter_reply + ' ' + this.inputValue[ii] + ' '+VALUE_FIELDS_DELIMITER+' ' : '' )
          }
        }

        return reply
      } else {
        return ''
      }
    }
  },
  mounted () {
    // The voter_reply is used to identify the specific special rule.  The voter_reply
    // string must be unique for each rule.  However, if there is only one rule,
    // then you don't need a voter_reply prefix.
    // The following code removes the voter_reply prefix from the input string.
    // You have to do this because...
    // When the voter leaves the page this component prefixes the string in the input box
    // with the voter_reply string.  The voter does not see the prefixed string.  The string
    // is written to the FPCA Additional Information section.  However, if the voter leaves
    // the page and then returns, the input box will contain the reply prefix
    // and the voter's initial reply.  On leaving the field a second time, this component
    // prefixes the entire string in the input box with the reply prefix.
    // You have to remove the reply prefix everytime you enter this component so that you
    // don't get duplicate prefixes.

    if (this.value) {
      if (this.isAtLeastOneTrigger) {
        let valueFields = this.value.split(VALUE_FIELDS_DELIMITER)
        
        if (this.rulesEnabled.length===1) {
          let theRule = this.rulesEnabled[0]
          let vr = this.stateRules[theRule].voter_reply.toString()
          if (vr!=='') {
            this.inputValue[theRule] = valueFields[0].replace(vr,'').trim()
          } else {
            this.inputValue[theRule] = valueFields[0].trim()
          }
        } else {
          for (let ii=0; ii<this.stateRules.length; ii++) {
            let vr = this.stateRules[ii].voter_reply.toString()
            if (vr!=='') {
              let foundRule = valueFields.find(item => item.includes(vr))
              if (foundRule) {
                this.inputValue[ii] = foundRule.replace(vr,'').trim()
              }
            }
          }
        }
      } else {
        this.setVal(null)
      }
    } else {
      this.setVal(null)
    }
  },
  methods: {
    setVal: function (val) {
      this.$emit('input', val)
    },
    getInputValueArray () {
      let arr = []
       for (let ii=0; ii<this.stateRules.length; ii++) {
        arr.push('')
       }
      return arr
    },
    getToolTipOpenArray () {
      let arr = []
       for (let ii=0; ii<this.stateRules.length; ii++) {
        arr.push({ "isOpen" : false })
       }
      return arr
    }
  }
}
