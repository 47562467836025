
/**
 * 2022-09-22 John Yee
 * re: list of countries in the drop down list
 * 
 * The 'intl-tel-input' plugin sorts "Åland Islands" at the bottom of the alphabetical
 * list of countries.
 * 
 * On my local machine I moved "Åland Islands" to after "Afghanistan" by editing files in
 * 'node_modules/intl-tel-input/' that contain the list of countries.  But, this will fail
 * when we push to Github and Netlify because node_modules doesn't get pushed.
 */
import intlTelInput from 'intl-tel-input'
import { mapGetters } from 'vuex'
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'
import { getNewVfaDate } from '~/utils/butterUtils'
const MAX_DIGITS_IN_PHONE_NUMBER = 15 // ITU E.164 specifies 15 digit maximum; ref: https://en.wikipedia.org/wiki/Telephone_numbering_plan

let phoneField // DOM input element for the phone number
let itix       // intl-tel-input instance

export default {
  name: 'Tel',
  props: [
    "fieldName",
    "fieldLabel",
    "phonesFromStore",
    "v",
    "dict"
  ],
  mixins: [fieldLabelAndTooltip],
  data () {
    return {
      countryDialCode: '',
      countryFocused: false,
      countryIso: '',
      isValidPhoneNumber: false,
      fullPhoneValue: '',
      tempValue: '',
      showKeypad: false,
      maxInputLength: 2*MAX_DIGITS_IN_PHONE_NUMBER // extra large max length; allows voter to pad the number
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    deviceOs () {
      return this.$store.state.userdata.device.os
    },
    ...mapGetters('userdata', ['userCountry'])
  },
  methods: {
    readFromSessionStorage(itemName) {
      return sessionStorage.getItem(itemName)
    },
    writeToSessionStorage(itemName, itemValue) {
      let value
      if (typeof itemValue === 'object') {
        value = JSON.stringify(itemValue)
      } else {
        value = itemValue
      }
      sessionStorage.setItem(itemName, value)
    },
    initIntlTelInput (telfield, myCtry) {
      intlTelInput(telfield, {
        customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
          return "e.g. " + selectedCountryPlaceholder;
        },
        geoIpLookup: function(success, failure) {
            success(myCtry);
            failure('US');
        },
        formatOnDisplay: true,
        initialCountry: "auto",
        nationalMode: true,
        numberType: "FIXED_LINE",
        placeholderNumberType: "FIXED_LINE",
        preferredCountries: [],
        separateDialCode: true,
        utilsScript: "/intl-tel-input/build/js/utils.js"
      })
    },
    doITIValidation () {
      if (this.tempValue) {
        this.isValidPhoneNumber = this.itix.isValidNumber()

        if (this.isValidPhoneNumber) {
          this.fullPhoneValue = this.itix.getNumber() // put the full international telephone number into the FPCA form
          this.tempValue = this.phoneField.value
        }
      } else {
        this.isValidPhoneNumber = true // an empty input field is considered a valid phone number
      }

      if (this.isValidPhoneNumber===null) {
        this.isValidPhoneNumber = false
      }
    },
    savePhone () {
      let ph = this.$store.state.phones
      let newPhones = Object.assign({}, ph, {"createdAt": getNewVfaDate().toISOString()})
      newPhones[this.fieldName].countryIso=this.countryIso
      newPhones[this.fieldName].dialCode=this.countryDialCode
      newPhones[this.fieldName].internationalNumber=this.fullPhoneValue
      newPhones[this.fieldName].nationalNumber=this.tempValue
      this.writeToSessionStorage('sessionstoragephones', newPhones)

      this.$store.commit('savePhones', newPhones)
    },
    emitValidation () {
      this.$emit('itinumber'+this.fieldName, 
        { 'isValidNumber': this.isValidPhoneNumber,
          'phoneNumber': this.fullPhoneValue
        }
      )
      this.$emit('delayTouch')
    },
    inputChangeHandler () {
      let phVal = this.phoneField.value
      this.tempValue = phVal.replace(/[^0-9 ]/g,'')
      this.phoneField.value = this.tempValue
      this.fullPhoneValue = this.tempValue ? ('+'+this.countryDialCode+this.tempValue) : ''
      this.savePhone ()
      this.$nextTick(()=>{this.doITIValidation(); this.emitValidation ()})
    },
    finalFormat () {
      /**
       * 2022-10-06 John Yee
       * 
       * setNumber
       * (1) formats a valid phone number into the country's format
       * (2) places the insertion point at the end of the string
       * 
       * If setNumber is called inside another function e.g. validation,
       * then the insertion point will jump to the end of the string and 
       * the voter is unable to use the left arrow to move the insertion
       * point inside the string to be able to edit the number.
       * So I call finalFormat at the very end of active input i.e. onblur.
       */
      if (this.fullPhoneValue) {
        this.itix.setNumber(this.fullPhoneValue)
      }
    },
    doKeyPad (val) {
      let pFV = this.phoneField.value
      this.phoneField.value = (val!=="delete") ? pFV += val : pFV.slice(0,pFV.length-1)
      this.inputChangeHandler() 
    },
  },
  mounted () {
    let sessionstoragephones = this.readFromSessionStorage('sessionstoragephones')
    let newPhone = sessionstoragephones ? JSON.parse(sessionstoragephones)[this.fieldName] : this.phonesFromStore[this.fieldName]
    this.countryIso = newPhone.countryIso ? newPhone.countryIso : (this.userCountry ? this.userCountry : 'US')
    this.countryDialCode = newPhone.dialCode
    this.tempValue = newPhone.nationalNumber
    this.fullPhoneValue = newPhone.internationalNumber

    this.phoneField = this.$refs[this.fieldName].$el.querySelector('input')
    this.initIntlTelInput(this.phoneField, this.countryIso)
    this.itix = window.intlTelInputGlobals.getInstance(this.phoneField)

    if (!this.countryDialCode) {
      let ctryData = this.itix.getSelectedCountryData()
      this.countryIso = ctryData.iso2 ? ctryData.iso2.toUpperCase() : 'US'
      this.countryDialCode = ctryData.dialCode ? ctryData.dialCode : '1'
    }
    if (this.tempValue) {
      this.tempValue = this.tempValue.replace(/[^0-9 ]/g,'')
      this.fullPhoneValue = '+'+this.countryDialCode+this.tempValue
      this.itix.setNumber(this.fullPhoneValue)
    }

    this.$nextTick(()=>{this.doITIValidation(); this.emitValidation ()})

    this.phoneField.addEventListener('countrychange', () => {
      let ctryData = this.itix.getSelectedCountryData()
      this.countryIso = ctryData.iso2 ? ctryData.iso2.toUpperCase() : 'US'
      this.countryDialCode = ctryData.dialCode ? ctryData.dialCode : '1'

      if (this.tempValue) {
        this.tempValue = this.tempValue.replace(/[^0-9 ]/g,'')
        this.fullPhoneValue = '+'+this.countryDialCode+this.tempValue
        this.itix.setNumber(this.fullPhoneValue)
      }

      this.savePhone ()
      this.$nextTick(()=>{this.doITIValidation(); this.emitValidation ()})
    })

    this.phoneField.addEventListener('keydown', (event) => {
      let evKey = event.key
      if (   /[^0-9 ]/.test(evKey)
          && !evKey.includes("Tab")
          && !evKey.includes("Arrow")
          && !evKey.includes("End")
          && !evKey.includes("Home")
          && !evKey.includes("Backspace")
          && !evKey.includes("Del")
         ) {
        event.preventDefault()
      }
    })

    this.phoneField.addEventListener('keyup', () => {
      this.inputChangeHandler()
    })

    this.phoneField.addEventListener('change', () => {
      this.inputChangeHandler()
    })
  }
}
