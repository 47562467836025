
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'salutation',
  props: [
    'label',
    'toolTipTitle',
    'dict'
  ],
  data () {
    return {
      baseClass: {
        'is-medium': true,
        'is-outlined': false,
        button: true
      }
    }
  },
  methods: {
    ...mapMutations('requests', ['update'])
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    salutation: {
      get () { return this.getCurrent.salutation },
      set (val) { this.update({salutation: val}) }
    },
    isSalutationMiss () { return this.salutation === 'miss' },
    isSalutationMrs () { return this.salutation === 'mrs' },
    isSalutationMs () { return this.salutation === 'ms' },
    isSalutationMr () { return this.salutation === 'mr' },

    ...mapGetters('requests', ['getCurrent'])
  }
}
