
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'

export default {
  name: 'PreviousName',
  mixins: [fieldLabelAndTooltip],
  props: [
    'label',
    'fieldName',
    'instructions',
    'value',
    'type',
    'message',
    'toolTipTitle',
    'toolTipContent',
    'required',
    'validations',
    'maxlengthFields',
    'dict',
    'v',
  ],
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    name () {
      return this.value && this.value.previousName
        ? this.value.previousName
        : ''
    },
    pName: {
      get () {
        if (typeof this.value === 'string' && this.value.length > 0) {
          return this.value.trim().replace(/\s+/g, ' ')
        } else if (
          this.value &&
          typeof this.value.previousName === 'string' &&
          this.value.previousName.length > 0
        ) {
          return this.value.previousName.trim().replace(/\s+/g, ' ')
        } else {
          return null
        }
      },
      set (value) {
        this.$emit('input', {
          previousName: value || null,
          usesPreviousName: this.value.usesPreviousName || false
        })
      }
    },
    usesPreviousName () {
      return this.value && this.value.usesPreviousName
        ? this.value.usesPreviousName
        : false
    }
  },
  data () {
    return {
      isOpen: false,
      baseClass: {
        'is-medium': true,
        'is-outlined': false,
        button: true
      }
    }
  },
  methods: {
    setName: function (val) {
      // console.log(val, this.value)
      this.$emit('input', {
        previousName: val,
        usesPreviousName: true
      })
      this.$emit('delayTouch', v);
    },
    setUsesPreviousName: function (val) {
      this.$emit('input', {
        // previousName: val ? this.name || null : null,
        previousName: val ? this.pName : '',
        usesPreviousName: val
      })
    }
  }
}
