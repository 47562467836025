
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Is-Registered',
  props: ['label', 'value', 'jurisdiction', 'jurisdictionType', 'validations', 'dict'],
  data () {
    return {
      baseClass: {
        'is-medium': true,
        'is-outlined': false,
        button: true
      }
    }
  },
  methods: {
    ...mapMutations('requests', ['update'])
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    isRegistered: {
      get () {
        return this.getCurrent.isRegistered
      },
      set (val) {
        this.update({ isRegistered: this.isRegistered === val ? null : val })
        this.$emit('delayTouch')
      }
    },
    registered: function () {
      return this.isRegistered === 'registered'
    },
    notregistered: function () {
      return this.isRegistered === 'notregistered'
    },
    unsure: function () {
      return this.isRegistered === 'unsure'
    },
    ...mapGetters('requests', ['getCurrent'])
  }
}
