
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Voter-Class',
  props: [
    'label',
    'value',
    'state',
    'toolTipTitle',
    'toolTipContent',
    'allowsNeverResided',
    'validations',
    'dict'
  ],
  data () {
    return {
      baseClass: {
        'is-medium': true,
        'is-outlined': false,
        button: true
      },
      isOpen: false
    }
  },
  methods: {
    sendVoterClass(voterClass) {
      this.$emit('hoveredVoterClass', voterClass)
    },
    ...mapMutations('requests', ['update'])
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    voterClass: {
      get () { return this.getCurrent.voterClass },
      set (val) { this.update({voterClass: val}) }
    },
    isMilitary: function () { return this.voterClass === 'military' },
    isMilSpouse: function () { return this.voterClass === 'milSpouse' },
    isUncertainReturn: function () { return this.voterClass === 'uncertainReturn' },
    isIntendToReturn: function () { return this.voterClass === 'intendToReturn' },
    isNeverResided: function () { return this.allowsNeverResided ? this.voterClass === 'neverResided' : '' },
    ...mapGetters('requests', ['getCurrent'])
  },
}
