
import { mapMutations, mapGetters } from 'vuex'
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'
import { getVFAParameters } from '~/utils/VFAParameters';

export default {
  name: 'EmailInput',
  mixins: [fieldLabelAndTooltip],
  data () {
    return {
      isEnteringAddress: false
    }
  },
  props: [
    'fieldName',
    'fieldLabel',
    'instructions',
    'message',
    'dict',
    'v',

    /**
     * 2023-06-28 John Yee
     * prop daJoinStatus is a workaround for the joinDa opt-in selections
     * I want to rewrite everything associated with joinDa because it is overloaded in the way it is used:
     * boolean, 'already a member', email address.
     * 
     * daJoinStatus is actually used in the mixin fieldLabelAndTooltip.js
     */
    'daJoinStatus'
  ],
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    commonFullDomains () {
      var rawString = getVFAParameters(this.$store).COMMON_FULL_DOMAINS
      rawString = rawString.split(" ").join("") // delete spaces at ends and within the string
      return rawString.split(",")  // split on commas to return a list
    },
    commonTopLevelDomains () {
      var rawString = getVFAParameters(this.$store).COMMON_TOP_LEVEL_DOMAINS
      rawString = rawString.split(" ").join("") // delete spaces at ends and within the string
      return rawString.split(",")  // split on commas to return a list
    },
    randomPresAddress () {
      // addresses in the list below are in the format are firstname.middleinitial_last2yearsinyearofbirth
      const presidentaddresses = ['George.W_32', 'John.A_35', 'Thomas.J_43', 'James.M_51', 'James.M_58', 'John.Q.A_67', 'Andrew.J_67', 'Martin.V.B_82', 'William.H.H_73', 'John.T_90', 'James.K.P_95', 'Zachary.T_84', 'Millard.F_00', 'Franklin.P_04', 'James.B_91', 'Abraham.L_09', 'Andrew.J_08', 'Ulysses.S.G_22', 'Rutherford.B.H_22', 'James.A.G_31', 'Chester.A.A_29', 'Grover.C_37', 'Benjamin.H_33', 'Grover.C_37', 'William.M_43', 'Theodore.R_58', 'William.H.T_57', 'Woodrow.W_56', 'Warren.G.H_65', 'Calvin.C_72', 'Herbert.H_74', 'Franklin.D.R_82', 'Harry.S.T_84', 'Dwight.D.E_90', 'John.F.K_17', 'Lyndon.B.J_08', 'Richard.N_13', 'Gerald.F_13', 'Jimmy.C_24', 'Ronald.R_11', 'George.H.W.B_24', 'Bill.C_46', 'George.W.B_46', 'Barack.O_61', 'Joe.R_42']
      // append a random commonFullDomain to create a fun fake address
      return `${presidentaddresses[Math.floor(Math.random() * presidentaddresses.length)]
                }@${this.commonFullDomains.filter(x => !/dem/.test(x))[
                  Math.floor(Math.random() * this.commonFullDomains.length)]}`
    },
    placeholderAddress () {
      return "e.g. "+this.randomPresAddress
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    MAXLENGTH_FIELDS () {
      return this.VFAParameters.MAXLENGTH_FIELDS
    },
    mailcheck () {
      return this.mailCheck(this.fieldValue, this.commonFullDomains, this.commonTopLevelDomains)
    },
    ...mapGetters('requests', ['getCurrent']),
    ...mapGetters('data', ['mailCheck'])
  },
  methods: {
    ...mapMutations('requests', ['update'])
  }
}
